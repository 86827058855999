import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const ItemIconWrapper = styled.div`
  width: 54px;
  height: 54px;
  overflow: hidden;
  background-color: #333;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &.open {
    width: 300px;
    height: 345px;
    flex: 0 0 300px;

    .item-icon {
      transform: none;
    }
  }

  ${p =>
    !p.hasImage &&
    `
      box-shadow: inset 5px 5px 5px 0px #000;
  `}
`;

const ItemIcon = styled.div.attrs({
  className: "item-icon",
})`
  width: 300px;
  height: auto;
  transform: translateX(-123px) translateY(-9px);
  z-index: 1;
`;

export default class itemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleIcon() {
    if (this.props.image) {
      this.setState(prevState => ({
        open: !prevState.open,
      }));
    }
  }

  render() {
    const { image } = this.props;
    const { open } = this.state;

    return (
      <ItemIconWrapper
        onClick={this.toggleIcon.bind(this)}
        hasImage={!!image}
        className={open ? "open" : ""}
      >
        <ItemIcon>
          {image && (
            <Img
              width="300px"
              fluid={image.fluid}
              className="d-block w-100"
              alt={image.description}
              loading="lazy"
            />
          )}
        </ItemIcon>
      </ItemIconWrapper>
    );
  }
}
