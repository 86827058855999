import React from "react";
import styled from "styled-components";

// Utils
import moment from "moment-timezone";

// Styled Elements
const TimeSpan = styled.span``;

const Server = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default class ServerTimes extends React.Component {
  constructor(props) {
    super(props);

    this.state = this._getTimes();
    this.timeout = null;
  }

  _getTimes() {
    return {
      america: moment()
        .tz("America/Anchorage")
        .format("ddd Do MMM - HH:mm"),
      europe: moment()
        .tz("Europe/London")
        .format("ddd Do MMM - HH:mm"),
      asia: moment()
        .tz("Asia/Hong_Kong")
        .format("ddd Do MMM - HH:mm"),
    };
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      this.setState(this._getTimes());
    }, 30000);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  render() {
    const { america, europe, asia } = this.state;
    return (
      <div className="card mb-4">
        <h2 className="card-header h5">Server Times</h2>
        <div className="card-body">
          <Server>
            America:
            <TimeSpan>{america}</TimeSpan>
          </Server>
          <Server>
            Europe:
            <TimeSpan>{europe}</TimeSpan>
          </Server>
          <Server>
            Asia:
            <TimeSpan>{asia}</TimeSpan>
          </Server>
        </div>
      </div>
    );
  }
}
