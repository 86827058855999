import React from "react";
import styled from "styled-components";

// Utils
import { getItemSlug } from "../utils/item";

// Components
import ItemIcon from "./item/ItemIcon";
import { Link } from "gatsby";

// Styled Elements
const Row = styled.li`
  display: flex;
  margin: 0 0 12px;
  padding: 8px;
  border: 1px solid #bbb;
  flex: 0 0 100%;
  background-color: #f6f6f6;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  flex: 1 1 calc(100% - 62px);

  p {
    margin: 0;
    font-weight: 500;
    letter-spacing: -1px;
  }
`;

const ItemName = styled.h2.attrs({
  className: "h5",
})`
  font-family: serif;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 4px;
  margin-bottom: 4px;
  text-transform: capitalize;

  a {
    text-decoration: none;
    font: inherit;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    background-color: #121822;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    color: ${p => (p.rarity ? p.rarity : "#000")};
    padding: 5px 8px;
    border-radius: 3px;
    margin-left: 12px;
  }
`;

const Stat = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin-right: 12px;
  font-style: italic;
  color: ${p => (p.positive ? "green" : "red")};

  &::before {
    content: "▲";
    ${p => !p.positive && `content: "▼"`};
    font-style: normal;
    margin-right: 4px;
  }
`;

const styledSubStats = {
  critChance: "Crit Chance",
  moveSpeed: "Move Speed",
  attackSpeed: "Attack Speed",
  manaMp: "Mana MP",
  magicRegen: "Magic Regen",
};

const rarityTypes = {
  yellow: "epic",
  orange: "mystical",
  red: "abyssal",
};

const SubStatList = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 756px) {
    flex-direction: row;
  }
`;

export default class GearRow extends React.Component {
  render() {
    const { gear } = this.props;
    const { type, name, images, rarity, subStats, baseAp, baseDp } = gear;

    let itemType = type;
    if (type === "sub" || type === "main") {
      itemType = undefined;
    }

    const slug = getItemSlug(type, name);

    return (
      <Row>
        <ItemIcon image={images && images[0]} />
        <ItemDetails>
          <ItemName rarity={rarity}>
            <Link to={slug} title={name}>
              {name} {itemType}
            </Link>{" "}
            <span>{rarityTypes[rarity]}</span>
          </ItemName>
          <p>
            {baseAp > 0 && `${baseAp}  AP`}
            {baseAp > 0 && baseDp > 0 && ` / `}
            {baseDp > 0 && `${baseDp}  DP`}
          </p>
          <SubStatList>
            {subStats &&
              subStats.map((stat, i) => {
                const positive = stat.amount > 0;
                let amount = stat.amount;
                if (stat.type === "percent") {
                  amount = amount + "%";
                }
                if (positive > 0) {
                  amount = "+" + amount;
                }
                return (
                  <Stat positive={positive} key={i}>
                    {amount} {styledSubStats[stat.name] || stat.name}
                  </Stat>
                );
              })}
          </SubStatList>
        </ItemDetails>
      </Row>
    );
  }
}
