import React from "react";
import styled from "styled-components";

// Components
import { Link } from "gatsby";

// Styled Elements
const ListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 8px;

  &::after {
    content: "";
    border-bottom: 1px dashed #ccc;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
`;

export default class CategoryNavigation extends React.Component {
  render() {
    const { categories } = this.props;

    return (
      <div className="card mb-4">
        <h2 className="card-header h5">Item Categories</h2>
        <div className="card-body">
          {categories.map((category, i) => {
            return (
              <ListItem key={i}>
                <Link to={`/${category.baseSlug}`}>{category.name}</Link>
                <span>{category.collection.length} items</span>
              </ListItem>
            );
          })}
        </div>
      </div>
    );
  }
}
